import React, { useContext } from "react"
import styled from "styled-components"
import toast from "react-hot-toast"
import { StoreContext } from "../context/storeContext" 

const AddToCartStyles = styled.button`
  margin-top: 1em;
  border-radius: 6px;
  border: 1px solid #000!important;
  padding: 1rem;
  background: #000;
  color: #fff;
  font-family: "raleway",Arial,Helvetica,sans-serif;
  font-weight: 200;
  font-size: 1.5rem;
  cursor: pointer;
  transition: background 0.2s;
  text-decoration: none;

  &:hover {
    background: rgba(0,0,0,0);
    color: #000;
    border: 3px solid #000;
    transition: background 0.2s;
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`

const AddToCart = ({ variantId }) => {

  const { addProductToCart } = useContext(StoreContext)

  const addProduct = async () => {
    const success = await addProductToCart(variantId) 
    if (success) {
      toast.success('Added to cart!')
    } else {
      toast.error('Could not add product to cart.');
    }
  }

  return (
    <AddToCartStyles onClick={() => addProduct()}>
      Add To Cart
    </AddToCartStyles>
  )
}

export default AddToCart